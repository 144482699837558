<template>
    <v-card>
        <v-card-text>
            <vue-editor v-model="contentHtml" :editorToolbar="customToolbar"></vue-editor>
        </v-card-text>
    </v-card>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { getFirestore, updateDoc, doc, serverTimestamp, Timestamp } from "firebase/firestore";
import moment from 'moment';


export default {
    components: {
        VueEditor
    },
    data() {
        return {
            dialog: false,
            contentHtml: '',
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],

                ["bold", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ color: [] }, { background: [] }],
                ["link"],
                ["clean"],

            ],
            loading: false

        }
    },
    mounted() {

    },
    methods: {
        get(){
            return this.contentHtml
        },
        set(value){
            this.contentHtml = value
        }
    },
}
</script>

<style></style>