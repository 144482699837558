<template>
    <div>
        <v-avatar :size="$props.small ? 40 : 65" style="display: block;color: white !important;" class="mx-auto "
            color="grey darken-2" :key="1" v-if="loading">
            <v-progress-circular :size="$props.small ? 40 : 65" indeterminate color="white"></v-progress-circular>
        </v-avatar>

        <v-avatar :size="$props.small ? 40 : 65" v-ripple v-else-if="!imageUrl && !loading" color="grey" :key="2"
            class=" mx-auto"
            style="display:block;background: linear-gradient(45deg, black, transparent);color: white !important;"
            @click="['admin','superuser'].includes($store.state.Auth.token.claims.type) || !$props.small? pickFile() : ''">
            <v-icon style="color: white !important;">
                    mdi-image
                </v-icon>
        </v-avatar>
        <v-avatar :size="$props.small ? 40 : 65" v-ripple v-else
            style="margin:auto;display: block;color: white !important;" :key="3"
            @click="['admin','superuser'].includes($store.state.Auth.token.claims.type) || !$props.small? pickFile() : ''">



            <img :src="imageUrl" alt="Profile image" />
        </v-avatar>

        <input type="file" @change="onFileChange" hidden ref="fileInput" v-show="false" />
        <v-dialog v-model="dialog" max-width="460px">

            <v-card>
                <v-card-title>
                    Editar imagen
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                </v-card-title>

                <v-card-text>

                    <vue-cropper v-if="selectedImage" ref="cropper" :guides="true" :src="selectedImage" :view-mode="1"
                        :aspect-ratio="1" :background="false" style="width: 100%; height: 200px;"></vue-cropper>

                    <v-divider class="my-2"></v-divider>

                    <v-alert border="left" color="primary darken-4">
                        Seleccione un área de la imagen para recortarla donde se vea su rostro claramente.
                    </v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-btn text @click="pickFile" v-if="selectedImage" rounded color="primary" :disabled="loading">

                        <v-icon left>mdi-image</v-icon>
                        Cambiar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" rounded v-if="selectedImage" @click="cropImage" :loading="loading">
                        <v-icon left>mdi-check</v-icon>
                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export default {
    props: {
        user: {
            type: Object,
            required: false,
            default: null,
        },
        small: {
            type: Boolean,
            required: false,
            default: false
        },
        useCivilId: {
            type: Boolean,
            default: false
        }
    },
    components: {
        VueCropper,
    },
    data() {
        return {
            loading: true,
            selectedImage: null,
            dialog: false,
            imageUrl: null, // This will hold the URL of the existing or newly uploaded image
        };
    },
    methods: {
        onFileChange(e) {
            const files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.selectedImage = null;
            const reader = new FileReader();
            reader.onload = (e) => {
                this.selectedImage = e.target.result;
                this.dialog = true;
            };
            reader.readAsDataURL(files[0]);
        },
        async cropImage() {
            this.imageUrl = this.$refs.cropper.getCroppedCanvas(
                {
                    width: 400,
                    height: 400
                }
            ).toDataURL();
            await this.uploadImage();
            this.dialog = false;
        },
        async uploadImage() {
            if (!this.imageUrl) return;
            try {
                this.dialog = false;
                this.loading = true;
                const storage = getStorage();
                const userId = this.$props.user && this.$props.user.id ? this.$props.user.id : this.$store.state.Auth.token.claims.user_id;
                const storageRef = ref(storage, `/profile/${userId}/profilePic.jpg`);
                const imageBlob = await fetch(this.imageUrl).then((r) => r.blob());
                await uploadBytes(storageRef, imageBlob);
                const uploadedImageUrl = await getDownloadURL(storageRef);
                this.imageUrl = uploadedImageUrl; // Update imageUrl to reflect the newly uploaded image
                this.$emit('update', { profilePic: uploadedImageUrl });

                this.$notify({
                    type: 'success',
                    title: 'Exito',
                    text: 'Imagen subida correctamente.',
                });
            } catch (error) {
                this.$notify({
                    type: 'error',
                    title: 'Error',
                    text: 'Error al subir la imagen. Por favor, inténtelo de nuevo.',
                });
                console.error('Error uploading image:', error);
            } finally {
                this.loading = false;
            }
        },
        pickFile() {
            this.$refs.fileInput.click();
        },
        async fetchProfileImage() {
            try {
                const storage = getStorage();
                let userId = this.$props.user && this.$props.user.id ? this.$props.user.id : this.$store.state.Auth.token.claims.user_id;

                if (this.$props.useCivilId) {
                    userId = this.$props.user.civilId
                }
                const storageRef = ref(storage, `/profile/${userId}/profilePic.jpg`);
                const url = await getDownloadURL(storageRef);
                this.imageUrl = url; // Set the imageUrl if it exists
            } catch (error) {
                console.log('No existing profile image found or error fetching it:', error);
                this.imageUrl = null; // Set imageUrl to null if there is no existing profile image                
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.fetchProfileImage(); // Try to fetch the profile image when the component mounts
    },
    watch: {
        user: {
            handler() {
                this.fetchProfileImage(); // Fetch the profile image when the user prop changes
            },
            deep: true,
        },
    },
};
</script>
