<template>
    <v-container>
        <!-- Loop over weeks -->
        <v-row v-for="(week, weekIndex) in weeks" :key="weekIndex" align-stretch>
            <v-col cols="12" class="text-center pa-0">
                <v-card class="rounded-0">
                    <v-card-title class="caption pa-1 primary darken-4">{{ 'Semana ' + (weekIndex + 1) }}
                        <v-spacer></v-spacer>
                        <v-tooltip bottom v-if="weekIndex + 1 == weeks.length">
                            <template v-slot:activator="{ on }">
                                <v-btn icon small v-on="on" @click="addWeek">
                                    <v-icon small>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Agregar semana</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon small v-on="on" @click="deleteWeek(weekIndex)">
                                    <v-icon small>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Eliminar semana</span>
                        </v-tooltip>
                    </v-card-title>
                </v-card>
            </v-col>

            <v-col v-for="(dayData, index) in week.days" :key="index" class="calendar-col fill-height d-flex flex-column" @click="selectDay(dayData, index)">
                <v-card class="calendar-day rounded-0 fill-height" height="100%">
                    <v-card-title class="day-number py-1 px-1">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn icon small v-on="on" @click="copyElems(dayData.exercises)" v-show="dayData.exercises.length > 0">
                                    <v-icon small>
                                        mdi-content-copy
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Copiar contenido</span>
                        </v-tooltip>

                        <v-tooltip top v-show="copy">
                            <template v-slot:activator="{ on }">
                                <v-btn icon small v-on="on" @click="pasteElems(dayData)" v-show="copy">
                                    <v-icon small>
                                        mdi-content-paste
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Pegar contenido</span>
                        </v-tooltip>


                        <v-tooltip top>
                            <template v-slot:activator="{ on }">



                                <v-badge color="error" overlap :content="selectedExercises.length" :value="selectedExercises.length" v-show="dayData.isSelected" >

                               
                                <v-btn icon small v-on="on" v-show="dayData.isSelected" @click="createClusterFromSelected(dayData)">
                                    <v-icon small>
                                        mdi-plus-box
                                    </v-icon>
                                </v-btn>
                                 </v-badge>
                            </template>
                            <span>
                              {{selectedExercises.length==0 ?'Selecciona ejercicios para crear un superset' : 'Crear superset/grupo con seleccionados'}} 
                            </span>
                        </v-tooltip>
                        





                        <v-spacer></v-spacer>
                        {{ index + 1 }}
                    </v-card-title>

                    <v-list dense class="fill-height py-0">
                        <draggable v-model="dayData.exercises" group="exercises" handle=".drag-handle" style="min-height:60px;">
                            <v-list-item v-for="(exercise, exIndex) in dayData.exercises" :key="exIndex" @click="toggleSelect(exIndex)" :class="{ 'selected-item': isSelected(exIndex) && dayData.isSelected }" class="drag-handle px-1">
                                
                                <template v-if="exercise.isCluster">
                                    <!-- Render cluster -->
                                    <div class="cluster-container">
                                        <v-list-item>
                                           
                                            <v-list-item-content>
                                                <v-list-item-title style="font-weight: bold;text-transform:uppercase">
                                                    <template v-if="exercise.title">
                                                         {{ exIndex + 1 }}) {{ exercise.title }}
                                                    </template>
                                                    <template v-else>
                                                       {{ exIndex + 1 }}) Superset/Grupo
                                                    </template>
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ exercise.sets }} sets
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-toolbar-items v-if="dayData.isSelected">
                                                <v-btn icon small @click="removeExercise(dayData, exIndex)">
                                                    <v-icon color="error" small>mdi-close</v-icon>
                                                </v-btn>

                                                <v-menu offset-y :close-on-content-click="false" width="300">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" text small icon>
                                                            <v-icon x-small size="15">mdi-pencil</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <!-- Menu content for editing cluster -->
                                                    <v-card>
                                                        <v-card-text>
                                                            <v-text-field label="Título" v-model="exercise.title" dense outlined></v-text-field>
                                                            <v-text-field label="Sets" v-model="exercise.sets" type="number" min="1" step="1" dense outlined></v-text-field>
                                                            <v-text-field label="Descanso (segundos)" v-model="exercise.restBetweenSets" type="number" min="1" step="1" dense outlined></v-text-field>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-menu>


                                            </v-toolbar-items>
                                        </v-list-item>

                                        <!-- Display exercises inside the cluster -->
                                        <v-list dense class="cluster-exercise-list py-0">
                                            <draggable v-model="exercise.exercises" group="exercises" handle=".drag-handle-cluster">
                                                <v-list-item v-for="(subExercise, subExIndex) in exercise.exercises" :key="subExIndex" class="clustered-exercise">
                                                
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                             {{ exIndex + 1 }}.{{ subExIndex + 1 }}
                                                            
                                                            {{ exerciseHashIdNames[subExercise.id] }}</v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            {{ subExercise.type === 'reps' ? subExercise.value + ' reps' : subExercise.value + `''` }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-toolbar-items v-if="dayData.isSelected">
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn v-on="on" icon small @click="removeClusterExercise(exIndex, subExIndex, dayData)">
                                                                    <v-icon color="error" small>mdi-close</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Eliminar</span>
                                                        </v-tooltip>

                                                        <v-menu offset-y :close-on-content-click="false" width="300">
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn v-on="on" text small icon class="drag-handle-cluster">
                                                                    <v-icon x-small size="15">mdi-pencil</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <!-- Menu content for editing sub-exercise -->
                                                            <v-card>
                                                                <v-card-text>
                                                                    <v-select v-model="subExercise.type" :items="exerciseTypes" label="Tipo" dense outlined></v-select>
                                                                    <v-text-field v-model="subExercise.value" :label="subExercise.type === 'reps' ? 'Repeticiones' : 'Segundos'" type="number" min="1" step="1" dense outlined></v-text-field>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-menu>

                                                        <v-btn icon small class="drag-handle-cluster" style="cursor:pointer;">
                                                            <v-icon>mdi-drag</v-icon>
                                                        </v-btn>

                                                
                                                    </v-toolbar-items>
                                                </v-list-item>
                                            </draggable>
                                        </v-list>
                                    </div>
                                </template>

                                <template v-else>
                                    <!-- Render regular exercise -->
                                 
                                    <v-list-item-content>
                                        <v-list-item-title style="font-weight: bold;text-transform:uppercase">
                                           {{ exIndex + 1 }}) {{ exerciseHashIdNames[exercise.id] }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ exercise.sets }} x {{ exercise.type === 'reps' ? exercise.value + ' reps' : exercise.value + `''` }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-toolbar-items v-if="dayData.isSelected">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon small @click="removeExercise(dayData, exIndex)" v-on="on">
                                                    <v-icon color="error" small>mdi-close</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>

                                        <v-menu offset-y :close-on-content-click="false" width="300">
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" text small icon>
                                                    <v-icon x-small size="15">mdi-pencil</v-icon>
                                                </v-btn>
                                            </template>
                                            <!-- Menu content for editing exercise -->
                                            <v-card>
                                                <v-card-text>
                                                    <v-select v-model="exercise.type" :items="exerciseTypes" label="Tipo" dense outlined></v-select>
                                                    <v-text-field label="Sets" v-model="exercise.sets" type="number" min="1" step="1" dense outlined></v-text-field>
                                                    <v-text-field v-model="exercise.value" :label="exercise.type === 'reps' ? 'Repeticiones' : 'Segundos'" type="number" min="1" step="1" dense outlined></v-text-field>
                                                    <v-text-field label="Descanso (segundos)" v-model="exercise.restBetweenSets" type="number" min="1" step="1" dense outlined></v-text-field>
                                                </v-card-text>
                                            </v-card>
                                        </v-menu>
                                    </v-toolbar-items>
                                </template>
                            </v-list-item>
                        </draggable>

                        <!-- Option to add new exercise -->
                        <v-menu :close-on-content-click="false" :offset-x="true" v-show="dayData.isSelected">
                            <template v-slot:activator="{ on }">
                                <v-list-item v-on="on" v-show="dayData.isSelected">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon left>
                                                mdi-plus
                                            </v-icon>
                                            Agregar Ejercicio
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <v-card>
                                <v-card-text>
                                    <v-select v-model="newExerciseType" :items="exerciseTypes" label="Tipo" dense outlined></v-select>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field label="Sets" v-model="newExerciseSets" type="number" min="1" step="1" dense outlined></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="newExerciseValue" :label="newExerciseType === 'reps' ? 'Repeticiones' : 'Segundos'" type="number" min="1" step="1" dense outlined></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-autocomplete v-model="selectedExercise" :items="exercises" hide-details hide-selected label="Seleccionar ejercicio" item-text="name" item-value="id" dense outlined @change="addExercise(dayData)"></v-autocomplete>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import draggable from 'vuedraggable';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

export default {
    props: {
        exercises: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    components: {
        draggable,
    },
    data() {
        return {
            drawer: false,
            weeks: [], 
            selectedExercise: null,
            newExerciseSets: 1,
            newExerciseType: 'reps',
            newExerciseValue: 10,
            exerciseTypes: [
                { text: 'Repeticiones', value: 'reps' },
                { text: 'Tiempo', value: 'time' },
            ],
            exerciseHashIdNames: {},
            copy: null,
            selectedExercises: [],
            dayData: {},

        };
    },
    mounted() {
        this.generateWeeks();
    },
    methods: {
        generateWeeks() {
            const numberOfWeeks = 4; 
            this.weeks = [];

            for (let i = 0; i < numberOfWeeks; i++) {
                const week = { title: null, days: [] };
                for (let j = 0; j < 7; j++) {
                    week.days.push({
                        warmup: [],
                        exercises: [],
                        cooldown: [],
                        notes: [],
                        form: [],
                        isSelected: false,
                        isRestDay: false,
                        title: null,
                    });
                }
                this.weeks.push(week);
            }
        },
        selectDay(dayData) {

            if(dayData.isRestDay) return;

            if(this.dayData === dayData) return;

            this.weeks.forEach(week => {
                week.days.forEach(day => {
                    day.isSelected = false;
                });
            });

            dayData.isSelected = true;
            this.selectedExercise = null;
            this.newExerciseSets = 1;
            this.newExerciseType = 'reps';
            this.newExerciseValue = 10;
            this.selectedExercises = [];

            this.dayData = dayData;
        },
        addExercise(dayData) {
            if (this.selectedExercise) {
                const selectedExerciseData = this.exercises.find(ex => ex.id === this.selectedExercise);

                if (selectedExerciseData) {
                    const newExercise = {
                        id: selectedExerciseData.id,
                        name: selectedExerciseData.name,
                        sets: this.newExerciseSets,
                        type: this.newExerciseType,
                        value: this.newExerciseValue,
                    };

                    dayData.exercises.push(newExercise);

                    this.selectedExercise = null;
                    this.newExerciseSets = 1;
                    this.newExerciseType = 'reps';
                    this.newExerciseValue = 10;
                }
            }
        },
        copyElems(exercises) {
            this.copy = JSON.parse(JSON.stringify(exercises));
        },
        pasteElems(dayData) {
            if (this.copy) {
                this.copy.forEach(exercise => {
                    dayData.exercises.push(exercise);
                });

                this.copy = null;
            }
        },
        removeExercise(dayData, index) {
            dayData.exercises.splice(index, 1);

            this.selectedExercises = this.selectedExercises.filter(i => i !== index);
        },
        removeClusterExercise(exIndex, subExIndex, dayData) {
            dayData.exercises[exIndex].exercises.splice(subExIndex, 1);
        },
        isSelected(index) {
            return this.selectedExercises.includes(index);
        },
        toggleSelect(index) {

            // If the exercise is a cluster, we don't want to select it
            if (this.dayData.exercises[index].isCluster) return;



            if (this.selectedExercises.includes(index)) {
                this.selectedExercises = this.selectedExercises.filter(i => i !== index);
            } else {
                this.selectedExercises.push(index);
            }

            
        },
        createClusterFromSelected(dayData) {
            if (this.selectedExercises.length > 0) {
                const selectedExercisesData = this.selectedExercises.map(index => dayData.exercises[index]);
                const newCluster = {
                    id: `cluster-${Date.now()}`,
                    type: 'reps',
                    isCluster: true,
                    title: null,
                    sets: 1,
                    exercises: selectedExercisesData,
                };

                dayData.exercises.push(newCluster);

                dayData.exercises = dayData.exercises.filter((_, index) => !this.selectedExercises.includes(index));
                this.selectedExercises = [];
            }
        },
        addWeek() {
            const week = { title: null, days: [] };
            for (let j = 0; j < 7; j++) {
                week.days.push({
                    warmup: [],
                    exercises: [],
                    cooldown: [],
                    notes: [],
                    form: [],
                    isSelected: false,
                    isRestDay: false,
                });
            }
            this.weeks.push(week);
        },
        deleteWeek(index) {
            this.weeks.splice(index, 1);
        },
        set(value){
            this.weeks = value;
        },
        get(){
            return this.weeks;
        }
    },
    watch: {
        exercises: {
            handler() {
                this.exerciseHashIdNames = this.exercises.reduce((acc, curr) => {
                    acc[curr.id] = curr.name;
                    return acc;
                }, {});
            },
            immediate: true,
        },
    },
};
</script>

<style scoped>
.calendar-col {
    padding: 0px;
    min-width: 14.2857%;
    height: auto;
}

.calendar-day {
    min-height: 100%;
    background-color: #f4f4f4;
}

.theme--dark .calendar-day {
    background-color: #333;
}

.selected-item {
    background-color: #e0f7fa;
}

.cluster-container {
    width: 100%;
    background-color: #f0f4f7;
    border-left: 5px solid #42a5f5;
}

.clustered-exercise {
    margin-bottom: 5px;
}

.drag-handle {
    cursor: move;
}

.theme--dark .selected-item {
    background-color: rgba(255, 255, 255, 0.2);
}

.theme--light .selected-item {
    background-color: rgba(0, 0, 0, 0.2);
}

.theme--dark .cluster-container {
    background-color: #1f2d3d;
    border-left-color: #f0f4f7;
}

.theme--light .cluster-container {
    background-color: #f0f4f7;
    border-left-color: #1f2d3d;
}

.cluster-container {
    width: 100%;
    border-left: 2px solid;
}
</style>
