<template>
    <v-container>

        <!-- planifications datatable -->
        <v-row no-gutters>
            <v-col cols="12">
                <v-card v-show="!planName" elevation="0" class="glass">
                    <v-card-title>
                        <h2 class="text-h5">Planificaciones</h2>
                        <v-spacer></v-spacer>
                        <v-btn dark @click="addNew" color="success" 
                            v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
                            <v-icon left>mdi-plus</v-icon>
                            Agregar</v-btn>
                    </v-card-title>

                    <v-card-text>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" rounded filled
                            class="rounded-lg" single-line dense hide-details></v-text-field>
                    </v-card-text>

                    <v-data-table :headers="headers" :items="planifications" :search="search" class="elevation-1"
                        @click:row="editPlanification" mobile-breakpoint="0" :loading="loading" hide-default-footer
                        disable-pagination sort-by="name">



                        <template v-slot:item.mainWorkoutCount="{item}">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn  v-on="on" @click.stop="trainingPlan=item;dialogMainWorkoutMembers = true; getMembersAndNonMembersMain(item)">
                                        <v-icon left>
                                            mdi-account-group
                                        </v-icon>

                                         {{ item.mainWorkoutCount }}
                                    </v-btn>
                                </template>
                                <span>Ver miembros</span>
                            </v-tooltip>
                        </template>

                        <template v-slot:item.routinesCount="{item}">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn  v-on="on"   @click.stop="trainingPlan=item;dialogPlanMembers = true; getMembersAndNonMembers(item)">
                                        <v-icon left>
                                            mdi-account-group
                                        </v-icon>

                                         {{ item.routinesCount }}
                                    </v-btn>
                                </template>
                                <span>Ver miembros</span>
                            </v-tooltip>
                        </template>


                        <template v-slot:item.type="{ item }">
                            {{item.type == 'matrix' ? 'Matriz' : item.type == 'matrixtext' ? 'Matriz Texto' : item.type == 'listado' ? 'Listado' : item.type == 'calendar' ? 'Calendario' : item.type == 'text' ? 'Texto' : 'Desconocido'}}

                        </template>

                        <template v-slot:item.tag="{ item }">
                            <v-chip v-for="tag in item.tag" :key="tag" class="mr-2" small>
                                {{ tag }}
                            </v-chip>
                        </template>

                        <template v-slot:item.color="{ item }">
                            <v-avatar size="35" :color="item.color">
                                <v-icon
                                    v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
                                    {{ item.visible ? 'mdi-eye' : 'mdi-eye-off' }}
                                </v-icon>
                            </v-avatar>
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <v-menu
                                v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')"
                                :close-on-content-click="false" :nudge-right="40"
                                transition="scroll-x-reverse-transition" offset-y>
                                <template v-slot:activator="{ on, attrs }">


                                    <v-btn fab v-bind="attrs" x-small elevation="0" v-on="on">
                                        <v-icon>
                                            mdi-dots-vertical
                                        </v-icon>

                                    </v-btn>
                                </template>

                                <v-card rounded :color="item.color">
                                    <v-card-title class="caption py-1">

                                    </v-card-title>
                                    <v-divider></v-divider>


                                    <v-list class="pt-0 pb-0">
                                        <v-list-item @click="editPlanification(item)">
                                            <v-list-item-icon>
                                                <v-icon>
                                                    mdi-pencil-outline
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>

                                        <v-list-item @click="clonePlanification(item)">
                                            <v-list-item-icon>
                                                <v-icon>
                                                    mdi-content-copy
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Usar como plantilla</v-list-item-title>
                                        </v-list-item>

                                        <v-divider></v-divider>

                                        <v-list-item @click="hidePlanification(item)" v-if="item.visible">
                                            <v-list-item-icon>
                                                <v-icon v-if="!loading">
                                                    mdi-eye-off
                                                </v-icon>
                                                <v-progress-circular v-else indeterminate color="white"
                                                    size="24"></v-progress-circular>

                                            </v-list-item-icon>
                                            <v-list-item-title>Deshabilitar</v-list-item-title>
                                        </v-list-item>

                                        <v-list-item v-else @click="showPlanification(item)">
                                            <v-list-item-icon>
                                                <v-icon v-if="!loading">
                                                    mdi-eye
                                                </v-icon>
                                                <v-progress-circular v-else indeterminate color="white"
                                                    size="24"></v-progress-circular>
                                            </v-list-item-icon>
                                            <v-list-item-title>
                                                Habilitar
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-divider></v-divider>


<!-- 
                                         <v-list-item
                                            @click="editPlanification(item); dialogPlanMembers = true; getMembersAndNonMembers(item)">
                                            <v-list-item-icon>
                                                <v-icon>
                                                    mdi-account-edit
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Miembros</v-list-item-title>
                                        </v-list-item>  -->
  <!--
                                        <v-list-item disabled
                                            @click="dialogPlanMembers = true; getMembersAndNonMembers(item)">
                                            <v-list-item-icon>
                                                <v-icon>
                                                    mdi-chart-areaspline
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>
                                                Estado miembros
                                            </v-list-item-title>
                                        </v-list-item>
                                        -->


                                        <v-divider></v-divider>

                                        <v-list-item @click="deletePlanification(item)">
                                            <v-list-item-icon>
                                                <v-icon color="error">
                                                    mdi-close
                                                </v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-card>




                            </v-menu>


                        </template>




                    </v-data-table>
                </v-card>

                <v-card v-show="planName" class="pa-0 glass" elevation="0" >
                    <v-card-title class="px-2">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon @click="goBack" v-on="on">
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                            </template>
                            <span>Volver a listado</span>
                        </v-tooltip>

                        <!-- A TOOLTIP WITH A VVATAR INSIDE WITH THE EYE TOOLTIP SHOULD SAY VISIBLE O OCULTA-->
                        <v-avatar size="20" :color="trainingPlan.color" class="mx-2">

                        </v-avatar>

                        <h2  v-bind:class="{
                                 'text-h6': planName && planName.length < 20,
                                 'caption': planName && planName.length > 20 && planName.length < 30,
                                'small-caption': planName && planName.length > 30,
                            
                             }">{{ 

                              trainingPlan.name
                              }}</h2>
                        
                        <v-chip v-if="trainingPlan.tag && !$vuetify.breakpoint.smAndDown" class="ml-2" v-for="tag in trainingPlan.tag" :key="tag"
                           x-small>
                            {{ tag }}   
                        </v-chip>

                        <v-spacer></v-spacer>

                         <!--EDIT TOOLTIP BUTTON-->
                        <v-tooltip bottom
                            v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
                            <template v-slot:activator="{ on }">
                                <v-btn icon small @click="showEditPlanification" v-on="on" class="mr-4">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                        <!--agregar nuevo bloque button-->
                        <v-btn left @click="addBloque" :disabled="loading" class="mr-4"
                            v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin') && (trainingPlan.type == 'matrix' || trainingPlan.type == 'matrixtext')">
                            <v-icon>mdi-plus</v-icon>
                            Agregar Bloque
                        </v-btn>

                        <!---SAVE BUTTON-->
                        <v-btn @click="storePlanification(trainingPlan)" color="success" class="ml-4" :loading="loading"
                            v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
                            <v-icon left>mdi-content-save</v-icon>
                            Guardar
                        </v-btn>

                    </v-card-title>

                    <v-card-text>
                        {{ trainingPlan.description }}
                    </v-card-text>

                    <TextHTML ref='text' v-if="trainingPlan.type == 'text' && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')" />
                    <v-card-text v-if="trainingPlan.type == 'text' && $store.state.Auth.token.claims.type == 'entrenador'" v-html="trainingPlan.bloques">

                    </v-card-text>

                    <Simple :exercises="exercises" ref='simple' v-if="trainingPlan.type == 'listado' && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin') " />


                    <SimpleView :exercises="trainingPlan.bloques" v-if="trainingPlan.type == 'listado' && $store.state.Auth.token.claims.type == 'entrenador' " />

                    <Calendar :exercises="exercises" ref='calendar' v-if="trainingPlan.type == 'calendar'" />




                    <template v-if="trainingPlan && trainingPlan.type && trainingPlan.type.includes('matrix')">

                        <v-row class="HOLDTABLES">



                            <draggable v-model="trainingPlan.bloques" handle=".drag-handle" style="display:contents;">
                                <v-col cols="12" :md="trainingPlan.bloques.length < 2 ? 12 : 6" class="pa-4"
                                    v-for="(bloque, bloqueIndex) in trainingPlan.bloques" :key="bloqueIndex">
                                    <v-toolbar class="rounded-lg">

                                        <h2 class="text-h5">
                                            <v-text-field dense v-model="bloque.title" label="Titulo" hide-details=""
                                                filled rounded class="rounded-lg"
                                                v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')"></v-text-field>
                                            <span v-else>{{ bloque.title || '' }}</span>

                                        </h2>
                                        <v-spacer></v-spacer>

                                        <span>
                                            Bloque {{ bloqueIndex + 1 }} / {{
                                                trainingPlan.bloques.length }}

                                        </span>
                                        <v-spacer></v-spacer>


                                        <v-toolbar-items
                                            v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">


                                            <v-tooltip bottom v-if="trainingPlan.bloques.length > 1">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn small icon v-on="on" class="drag-handle">
                                                        <v-icon>
                                                            mdi-drag
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Arrastra para ordenar</span>
                                            </v-tooltip>



                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn small icon v-on="on" @click="addDay(bloqueIndex)">
                                                        <v-icon>mdi-plus</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Agregar Día/Semana</span>
                                            </v-tooltip>



                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn color="error" small icon v-on="on"
                                                        @click="trainingPlan.bloques.splice(bloqueIndex, 1)">
                                                        <v-icon>mdi-close</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Eliminar Bloque</span>
                                            </v-tooltip>


                                        </v-toolbar-items>

                                    </v-toolbar>

                                    <v-data-table v-if="bloque.days.length > 0" :headers="headers2" :items="bloque.days"
                                        class="elevation-4" disable-pagination hide-default-footer disable-sort
                                        mobile-breakpoint="0">

                                        <template v-slot:item.ejercicioDiario="{ item, index }">
                                            <v-textarea class="py-2 rounded-lg" style="min-width:300px;max-width:400px"
                                                rows="1" auto-grow v-model="item.ejercicioDiario"
                                                :label="'Dia ' + (index + 1)" v-if="trainingPlan.type == 'matrixtext'"
                                                filled rounded
                                                :readonly="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type != 'superuser' && $store.state.Auth.token.claims.type != 'admin')"
                                                hide-details=""></v-textarea>

                                            <v-autocomplete v-model="item.ejercicioDiario" filled rounded dense
                                                style="max-width:300px" :items="exercises" hide-selected chips
                                                hide-details="" class="rounded-lg my-2" color="blue-grey lighten-2"
                                                :label="'Dia ' + (index + 1)" item-text="name" item-value="id" multiple
                                                v-if="$store.state.Auth.token.claims.type && trainingPlan.type == 'matrix'">
                                                <template v-slot:selection="data">
                                                    <v-chip class="my-2" v-bind="data.attrs"
                                                        :input-value="data.selected" close @click="data.select"
                                                        @click:close="removeFromEjercicioDiario(index, bloqueIndex)">
                                                        <v-avatar left>
                                                            <v-img :src="data.item.thumbnail"
                                                                v-if="data.item.thumbnail"></v-img>
                                                            <v-icon v-else small>mdi-dumbbell</v-icon>
                                                        </v-avatar>
                                                        {{ data.item.name }}
                                                    </v-chip>
                                                </template>
                                                <template v-slot:item="data">
                                                    <template v-if="typeof data.item !== 'object'">
                                                        <v-list-item-content>
                                                            {{data.item.name}}
                                                        </v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                        <v-list-item-avatar>
                                                            <img :src="data.item.thumbnail" v-if="data.item.thumbnail">
                                                            <v-icon v-else>mdi-dumbbell</v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title
                                                                >{{data.item.name}}
                                                                </v-list-item-title>

                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>

                                        </template>
                                        <template v-slot:item.cuantificacion="{ item, index }">
                                            <v-textarea rows="1" auto-grow v-model="item.cuantificacion" filled rounded
                                                dense class="rounded-lg" hide-details="" style="min-width:150px"
                                                :label="'Semana ' + (index + 1)"></v-textarea>
                                        </template>
                                        <template v-slot:item.metodo="{ item }">
                                            <v-textarea rows="1" auto-grow v-model="item.metodo" filled rounded dense
                                                class="rounded-lg" hide-details="" style="min-width:150px"
                                                label="Método"></v-textarea>
                                        </template>
                                        <template v-slot:item.rpe="{ item }">
                                            <v-text-field rows="1" auto-grow v-model="item.rpe" filled rounded
                                                type="number" min="1" max="10" step="1" dense class="rounded-lg"
                                                hide-details="" style="min-width:100px" label="RPE"></v-text-field>
                                        </template>


                                        <template v-slot:item.actions="{ item }">



                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn small icon v-on="on"
                                                        @click="bloque.days.splice(bloque.days.indexOf(item), 1)">
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Eliminar Dia</span>

                                            </v-tooltip>



                                        </template>

                                    </v-data-table>

                                    <v-card v-else>
                                        <v-card-text>
                                            <v-row>

                                                <v-col cols="12">
                                                    <v-autocomplete v-model="bloque.reference" filled rounded dense
                                                        :items="allBlocksList" hide-details="" class="rounded-lg"
                                                        label="Bloque de referencia">

                                                        <!--each item inside the template data should show the color planificationname+ bloque title or bloque index-->
                                                        <template v-slot:item="{ item }">
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    <v-avatar size="30"
                                                                        :color="planifications.find(plan => plan.id == item.planificationId).color">

                                                                    </v-avatar>
                                                                    {{ item.planificationName }} - {{ item.title ?
                                                                        item.title : item.blockId }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>

                                                        <template v-slot:selection="data">
                                                            <v-list-item-content class="px-0">
                                                                <v-list-item-title>
                                                                    <v-avatar size="30"
                                                                        :color="planifications.find(plan => plan.id == data.item.planificationId).color">

                                                                    </v-avatar>
                                                                    {{ data.item.planificationName }} - {{
                                                                        data.item.title ?
                                                                            data.item.title : data.item.blockId }}
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </template>





                                                    </v-autocomplete>


                                                </v-col>


                                                <v-col>
                                                    <v-btn @click="addDay(bloqueIndex)" color="primary"  block>
                                                        <v-icon>
                                                            mdi-plus
                                                        </v-icon>
                                                        Agregar Dia
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>

                                </v-col>

                            </draggable>

                        </v-row>
                    </template>
                </v-card>
            </v-col>


        </v-row>

        <v-dialog v-model="dialogPlanMembers" fullscreen persistent>
            <v-card class="rounded-0">
                <v-card-title v-bind:class="{ 'isCapacitorDialog': isCapacitor }">

                    <v-btn class="mr-4" icon @click="dialogPlanMembers = false; trainingPlan={};">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <h2 class="text-h5">Miembros</h2>

                </v-card-title>
                <v-card-text>



                    <v-row>
                        <v-col cols="12" md="6">
 <v-data-table :search="searchMembers" :headers="headersMembers" :items="planMembers" 
                        class="elevation-1" hide-default-footer mobile-breakpoint="0" :loading="loadingDelete"
                        disable-pagination>
                        <template v-slot:item.actions="{ item }">
                            <v-btn @click="deletePlanMember(item)" small color="error" :disabled="loadingDelete">
                                <v-icon small left>
                                    mdi-close
                                </v-icon>
                                Eliminar
                            </v-btn>
                        </template>

                        <template v-slot:top>
                            <v-text-field dense  filled rounded hide-details="" v-model="searchMembers" label="Buscar"
                                append-icon="mdi-magnify" class="my-2 rounded-lg"></v-text-field>
                        </template>
                    </v-data-table>
                        </v-col>
                         <v-col cols="12" md="6">
                             <v-data-table :search="searchMembers" :headers="headersMembers" :items="planNonMembers"
                       mobile-breakpoint="0" :loading="loadingDelete" class="elevation-1"
                      >
                        <template v-slot:item.actions="{ item }">
                            <v-btn @click="addPlanMember(item)" small color="success" :disabled="loadingDelete">
                                <v-icon small left>
                                    mdi-plus
                                </v-icon>
                                Agregar
                            </v-btn>
                        </template>
                        <template v-slot:top>
                            <v-text-field dense filled rounded hide-details="" v-model="searchMembers" label="Buscar"
                                append-icon="mdi-magnify" class="my-2 rounded-lg"></v-text-field>
                        </template>


                    </v-data-table>
                         </v-col>

                    </v-row>
                   

                   


                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="addPlanificationDialog" transition="dialog-bottom-transition"
            :fullscreen="$vuetify.breakpoint.smAndDown" width="500px">
            <v-card class="rounded-0">
                <v-card-title v-bind:class="{ 'isCapacitorDialog': isCapacitor }">
                    <v-btn icon @click="addPlanificationDialog = false">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-spacer></v-spacer>
                    {{
                        trainingPlan.id ? 'Editar' : 'Nueva Planificación'
                    }}
                    <v-spacer></v-spacer>

                    <v-icon style="visibility:hidden">yo</v-icon> 

                </v-card-title>
                <v-card-text>
                    <v-form ref="trainingPlan">
                        <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
                            <v-col cols="12" md="12">
                                <v-text-field dense filled rounded v-model="trainingPlan.name" class="rounded-lg"
                                    :rules="[v => !!v || 'Campo requerido']" label="Nombre"></v-text-field>

                                <v-textarea dense rows="3" filled rounded class="rounded-lg" auto-grow
                                    :rules="[v => !!v || 'Campo requerido']" v-model="trainingPlan.description"
                                    label="Descripcion"></v-textarea>
                                <v-select dense filled rounded v-model="trainingPlan.type" class="rounded-lg"
                                    v-if="!trainingPlan.id" :rules="[v => !!v || 'Campo requerido']"
                                    :items="planificationTypes" label="Tipo"></v-select>

                                <v-alert type="info" v-if="!trainingPlan.id && trainingPlan.type && trainingPlan.type.includes('matrix')" text>
                                     Solo para usuarios avanzados, se recomienda usar la planificación de tipo listado o
                                    calendario.
                                </v-alert>


                                <v-subheader class="px-0">
                                    Opcionales
                                </v-subheader>

                                <v-select dense filled rounded v-model="trainingPlan.tag" multiple chips
                                    class="rounded-lg" :rules="[v => !!v || 'Campo requerido']"
                                    :items="planificationTags" label="Etiquetas"></v-select>

                                <v-menu
                                    v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')"
                                    v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on, attrs }">


                                        <v-btn :color="trainingPlan.color" x-large v-bind="attrs" block v-on="on">
                                            <v-icon left>
                                                mdi-palette
                                            </v-icon>
                                            Color
                                        </v-btn>
                                    </template>

                                    <v-card>
                                        <v-container>

                                            <v-row>
                                                <v-col cols="12">
                                                    <v-color-picker v-model="trainingPlan.color" v-if="trainingPlan"
                                                        hide-canvas hide-inputs show-swatches></v-color-picker>
                                                </v-col>
                                            </v-row>

                                        </v-container>
                                    </v-card>
                                </v-menu>

                            </v-col>

                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions v-show="!trainingPlan.id">
                    <v-btn text @click="addPlanificationDialog = false" :disabled="loading">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="storePlanification(trainingPlan)" color="success" :loading="loading" s>
                        <v-icon left>
                            mdi-plus
                        </v-icon>
                        {{ trainingPlan.id ? 'Actualizar' : 'Agregar' }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="dialogMainWorkoutMembers" fullscreen transition="dialog-bottom-transition">
            <v-card>
                <v-card-title>
                    <v-btn class="mr-4" icon @click="dialogMainWorkoutMembers = false">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <h2 class="text-h5">Miembros</h2>
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-data-table :search="searchMembers" :headers="headersMembersMain" :items="planMembers"
                                class="elevation-1" hide-default-footer mobile-breakpoint="0" :loading="loadingDelete"
                                disable-pagination>
                                <template v-slot:item.actions="{ item }">
                                    <v-btn @click="editPlanMember(item)" small :disabled="loadingDelete">
                                        <v-icon small left>
                                            mdi-pencil
                                        </v-icon>
                                        Editar
                                    </v-btn>
                                </template>

                                <template v-slot:item.mainWorkout.start="{ item }">
                                   {{ getFormattedDate(item.mainWorkout.start)}}
                                </template>




                                <template v-slot:top>
                                    <v-text-field dense filled rounded hide-details="" v-model="searchMembers" label="Buscar"
                                        append-icon="mdi-magnify" class="my-2 rounded-lg"></v-text-field>
                                </template>
                            </v-data-table>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-data-table :search="searchMembers" :headers="headersMembers" :items="planNonMembers"
                                mobile-breakpoint="0" :loading="loadingDelete" class="elevation-1">
                                <template v-slot:item.actions="{ item }">
                                    <v-btn @click="editPlanMember(item)" small color="success" :disabled="loadingDelete">
                                        <v-icon small left>
                                            mdi-plus
                                        </v-icon>
                                        Agregar
                                    </v-btn>
                                </template>
                                <template v-slot:top>
                                    <v-text-field dense filled rounded hide-details="" v-model="searchMembers" label="Buscar"
                                        append-icon="mdi-magnify" class="my-2 rounded-lg"></v-text-field>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>





        </v-dialog>


        <v-dialog v-model="editMainWorkoutMember" transition="dialog-bottom-transition" max-width="500">
            <v-card v-if="selectedMember && selectedMember.id">
                <v-toolbar flat >
                    <v-btn class="ml-2" icon @click="editMainWorkoutMember = false">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                              <UserImage class="mx-2" :small="true" :user="selectedMember" />

                    {{selectedMember.displayName}}
                </v-toolbar>
                <v-card-text>
                    <UserWorkouts :key="selectedMember.id" @main-workout-reset="resetMainWorkout" :planificationsArray="planifications" @main-workout-updated="updateMainWorkout" :user="selectedMember"></UserWorkouts>

                </v-card-text>

            </v-card>

        </v-dialog>

    </v-container>
</template>

<script>
import moment from 'moment';
import { Capacitor } from '@capacitor/core';

import { getFirestore, doc, setDoc, getDoc, getDocs, deleteDoc, collection, query, where, updateDoc, addDoc } from "firebase/firestore";

import TextHTML from "@/components/planifications/TextHTML.vue";
import Simple from "@/components/planifications/Simple.vue";
import Calendar from './planifications/Calendar.vue';
import draggable from 'vuedraggable';


import SimpleView from "@/components/planifications/view/Simple.vue";

import { logAuditEvent } from '@/error/audit.js';
import User from "@/models/user.js";
import UserWorkouts from '@/components/planifications/UserWorkouts.vue';
import UserImage from '@/components/profile/UserImage.vue';


export default {
    components: {
        TextHTML,
        Simple,
        Calendar,
        draggable,
        SimpleView,
        UserWorkouts,
        UserImage
    },
    data() {
        return {
            editMainWorkoutMember: false,
            selectedMember:{},

            isCapacitor: Capacitor.isNativePlatform(),
            searchMembers: null,
            searchNonMembers: null,
            loadingDelete: false,
            headersMembers: [
                { text: 'Nombre', value: 'displayName' },
                { text: '', value: 'actions', sortable: false }
            ],

            headersMembersMain:[
                { text: 'Nombre', value: 'displayName' },
                { text: 'Fecha inicio', value: 'mainWorkout.start' },
                { text: '', value: 'actions', sortable: false }
            ],
            dialogPlanMembers: false,
            planMembers: [],
            planNonMembers: [],
            menu: false,
            menu2: false,
            headers2: [
                { text: 'Ejercicios', value: 'ejercicioDiario' },
                { text: 'Cuantificación', value: 'cuantificacion' },
                { text: 'Método', value: 'metodo' },
                { text: 'RPE', value: 'rpe' },
            ],
            trainingPlan: {
                name: null,
                color: null,
                description: null,
                type: null,
                bloques: [
                    {
                        reference: false,
                        title: 'Parte A',
                        days: [
                        ]
                    },
                    {
                        reference: false,
                        title: 'Parte B',
                        days: []
                    }
                ]
            },
            /////////
            loading: true,
            dialogPlanification: false,
            planification: null,
            headers: [
                { text: '', value: 'color' },
                { text: 'Nombre', value: 'name' },
                { text: 'Etiquetas', value: 'tag' },
              



            ],
            search: null,
            tabsDAYS: null,
            tabs: null,
            planificationTypes: [
                { text: "Texto", value: "text" },
                { text: "Listado", value: "listado" },
                //{ text: "Calendario", value: "calendar" },
                { text: "Matriz Texto", value: "matrixtext" },

                { text: "Matriz", value: "matrix" }
            ],
            planificationTags: ['Fitness', 'Deportivo', 'Hipertrófico', 'Fuerza', 'Resistencia', 'Potencia', 'Velocidad','Cardio','Flexibilidad', 'Movilidad', 'Estabilidad', 'Calentamiento', 'Rehabilitación'],
            addPlanificationDialog: false,
            newPlanification: {
                name: '',
                type: '',
                color: '',
                weeks: 4,
                daysPerWeek: 5,
            },
            planifications: [],
            exercises: [],
            planName: null,
            users: [],
            dialogMainWorkoutMembers: false,
        };
    },
   async mounted() {
        if (this.$store.state.Auth.token.claims.type && this.$store.state.Auth.token.claims.type == "superuser" || this.$store.state.Auth.token.claims.type == "admin") {
            this.headers.push({ text: 'Principal', value: 'mainWorkoutCount' });
            this.headers.push({ text: 'Secundario', value: 'routinesCount' });

             this.headers.push({ text: 'Tipo', value: 'type' });
            this.headers.push({ text: '', value: 'actions', sortable: false });
            this.headers2.push({ text: '', value: 'actions', sortable: false });

        }

        await this.getPlanifications()
        if( this.$store.state.Auth.token.claims.type == "superuser" || this.$store.state.Auth.token.claims.type == "admin"){
            const db = getFirestore();
            //get users not disabled
            const q = query(collection(db, "users"), where("disabled", "==", false));
            const querySnapshot = await getDocs(q);

            let users = querySnapshot.docs.map(doc => (
                new User({ id: doc.id, ...doc.data() })
            ));

            //sort by displayName
            users.sort((a, b) => (a.displayName > b.displayName) ? 1 : -1);


            this.users = users;
            this.planifications = this.processUserData(this.planifications, users);
        }
        await this.fetchExercises()
    },
    methods: {
        resetMainWorkout(mainWorkout){

            //remove from the planMembers and add to the planNonMembers using this.selectedMember
            this.planMembers = this.planMembers.filter(user => user.id !== this.selectedMember.id);
            this.planNonMembers.push(this.selectedMember);

            //update the main workout for the user
            this.selectedMember.mainWorkout = mainWorkout;

            //also update the count of main workouts
            this.planifications = this.processUserData(this.planifications, this.users);

            this.editMainWorkoutMember = false;

        },
        updateMainWorkout(mainWorkout){
            //update the main workout for the user
            this.selectedMember.mainWorkout = mainWorkout;

            //process the members and  non members
            this.planMembers = this.users.filter(user => user.mainWorkout && user.mainWorkout.plan_id === this.trainingPlan.id);
            this.planNonMembers = this.users.filter(user => !user.mainWorkout || user.mainWorkout.plan_id !== this.trainingPlan.id);

            //also update the count of main workouts
            this.planifications = this.processUserData(this.planifications, this.users);
            

            
            this.editMainWorkoutMember = false;
        },




        getFormattedDate(date) {
            return 'Hace '+ moment(date, 'YYYY-MM-DD').locale('es').fromNow(true)
        },



        editPlanMember(user){
            this.selectedMember = user;
            this.editMainWorkoutMember = true;
        },



         processUserData(planifications, users) {
            return planifications.map(plan => {
                let mainWorkoutCount = 0;
                let routinesCount = 0;

                users.forEach(user => {
                if (user.mainWorkout && user.mainWorkout.plan_id === plan.id) {
                    mainWorkoutCount++;
                }

                if (user.routines && user.routines.includes(plan.id)) {
                    routinesCount++;
                }
                });

                return {
                ...plan,
                mainWorkoutCount,
                routinesCount
                };
            });
            }
        ,
        selectPlanification(planification) {
            this.trainingPlan = JSON.parse(JSON.stringify(planification));
            // Update the URL when a board is selected
            this.planName = planification.name
        },
        goBack() {
            this.planName = null;
            this.trainingPlan = {}
        },
        formatName(name) {
            return name.toLowerCase().replace(/\s+/g, '-');
        },
        async loadBoardFromURL() {
            const planName = this.$route.params.planName;
            if (!planName) return;

            const planification = this.planifications.find(planification => this.formatName(planification.name) === planName);
            if (planification) {
                this.selectPlanification(planification);
            }
        },
        addNew() {

            this.trainingPlan = {
                name: null,
                color: '',
                mode: null,
                description: null,
                type: null,
                visible: false,
                bloques: []
            }

            this.addPlanificationDialog = true

        },
        addDay(bloqueIndex) {
            if (this.trainingPlan.bloques[bloqueIndex].days.length < 7) {
                this.trainingPlan.bloques[bloqueIndex].days.push({
                    ejercicioDiario: '',
                    cuantificacion: '',
                    metodo: '',
                    rpe: ''
                });
            }

        },

        addBloque() {

            this.trainingPlan.bloques.push({
                reference: false,
                title: 'Bloque ' + (this.trainingPlan.bloques.length + 1),
                days: []
            });
        },

        saveTrainingPlan() {
            // Save the training plan
            // ...
        }
        ,
        async getPlanifications() {

            const db = getFirestore();

            if (this.$store.state.Auth.token.claims.type && this.$store.state.Auth.token.claims.type == "entrenador") {
                const q = query(collection(db, "planifications"), where("visible", "==", true));
                const results = await getDocs(q);

                this.planifications = results.docs.map(doc => ({
                    id: doc.id, // Store the document ID
                    ...doc.data() // Spread the rest of the document data
                }));

            } else {
                const query = await getDocs(collection(db, `planifications`));

                this.planifications = query.docs.map(doc => ({
                    id: doc.id, // Store the document ID
                    ...doc.data() // Spread the rest of the document data
                }));
            }

            await logAuditEvent('read', this.$store.state.Auth.token.claims.user_id, `Read all planifications`);

           
        },
        addPlanification(id) {
            let copy = JSON.parse(JSON.stringify(this.trainingPlan));

            this.planifications.push({
                ...copy,
                id: id,
                visible: copy.visible || false
            });

            this.addPlanificationDialog = false;

            this.dialogPlanification = false;

        },
        async deletePlanification(planification) {
            let confirm = await this.$confirm(
                "Seguro que quieres eliminar esta planificacion",
                {
                    color: "error", title: "Eliminar planificacion",
                    buttonTrueText: "CONFIRMAR",
                    buttonFalseText: "CANCELAR"
                }
            );
            if (!confirm) return;
            const db = getFirestore();
            let result = await deleteDoc(doc(db, "planifications", planification.id));
            const index = this.planifications.indexOf(planification);
            this.planifications.splice(index, 1);
        
            this.$notify({
                group: 'feedback',
                title: 'Planificacion eliminada',
                text: 'Eliminado correctamente',
                type: 'success',
            });

            await logAuditEvent('delete', this.$store.state.Auth.token.claims.user_id, `Deleted planification ${planification.name} with data ${JSON.stringify(planification)}`);
        },
      
        async deleteDay(planification, day) {
            let confirm = await this.$confirm(
                "Seguro que quieres eliminar este dia",
                {
                    color: "error", title: "Confirmacion",
                    buttonTrueText: "CONFIRMAR",
                    buttonFalseText: "CANCELAR"
                }
            );
            if (!confirm) return;

            const index = planification.days.indexOf(day);
            planification.days.splice(index, 1);
        },
        addExercise(day) {
            day.exercises.push({
                isblock: false,
                name: 'nuevo',
                description: 'dem',
            });
        },
        deleteExercise(day, exercise) {
            const index = day.exercises.indexOf(exercise);
            day.exercises.splice(index, 1);
        },
        async storePlanification(planification, bypass = false) {

            if(!bypass){
                if (!planification.id) {
                    let validate = this.$refs.trainingPlan.validate();
                    if (!validate) return;
                }
            }

            // check type of planification and if not matrix or matrixtext, use the get method of its reference component
            if (planification.type == 'text') {
                planification.bloques = this.$refs.text.get();
            } else if (planification.type == 'listado') {
                planification.bloques = this.$refs.simple.get();
            } else if (planification.type == 'calendar') {
                planification.bloques = this.$refs.calendar.get();
            } else if (planification.type == 'matrix' || planification.type == 'matrixtext') {
               if(!planification.id){
                planification.bloques = [
                    {
                        days: [
                            {
                                ejercicioDiario: null,
                                cuantificacion: '',
                                metodo: '',
                                rpe: ''
                            }, {
                                ejercicioDiario: null,
                                cuantificacion: '',
                                metodo: '',
                                rpe: ''
                            }, {
                                ejercicioDiario: null,
                                cuantificacion: '',
                                metodo: '',
                                rpe: ''
                            }, {
                                ejercicioDiario: null,
                                cuantificacion: '',
                                metodo: '',
                                rpe: ''
                            }, {
                                ejercicioDiario: null,
                                cuantificacion: '',
                                metodo: '',
                                rpe: ''
                            }
                        ]
                    },
                    {
                        days: [
                             {
                                ejercicioDiario: null,
                                cuantificacion: '',
                                metodo: '',
                                rpe: ''
                            }, {
                                ejercicioDiario: null,
                                cuantificacion: '',
                                metodo: '',
                                rpe: ''
                            }, {
                                ejercicioDiario: null,
                                cuantificacion: '',
                                metodo: '',
                                rpe: ''
                            }, {
                                ejercicioDiario: null,
                                cuantificacion: '',
                                metodo: '',
                                rpe: ''
                            }, {
                                ejercicioDiario: null,
                                cuantificacion: '',
                                metodo: '',
                                rpe: ''
                            }
                        ]
                    }
                ]
               }

                /// if type =='matrix' ensure all ejericicioDiario are arrays, if not make them empty arrays

                if(planification.type == 'matrix'){
                    planification.bloques.forEach(bloque => {
                        bloque.days.forEach(day => {
                            if (!Array.isArray(day.ejercicioDiario)) {
                                day.ejercicioDiario = [];
                            }
                        });
                    });
                }

            }          


            this.loading = true;
            const db = getFirestore();
            try {
                //delete count of main workouts and routines
                delete planification.mainWorkoutCount;
                delete planification.routinesCount;


                
                // Check if we're updating an existing planification or adding a new one
                if (planification.id) {
                    // Existing planification, update it
                    const planificationRef = doc(db, `planifications`, planification.id);
                    await setDoc(planificationRef, planification, { merge: false });

                    

                    this.$notify({
                        group: 'feedback',
                        title: 'Planificación actualizada',
                        text: 'La planificación se ha actualizado correctamente',
                        type: 'success',
                    });

                    await logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `Planification ${planification.name} updated data ${JSON.stringify(planification)}`);
                } else {
                    // New planification, add it
                    await addDoc(collection(db, `planifications`), planification);
                    this.$notify({
                        group: 'feedback',
                        title: 'Planificación guardada',
                        text: 'La planificación se ha guardado correctamente',
                        type: 'success',
                    });

                    await logAuditEvent('create', this.$store.state.Auth.token.claims.user_id, `Planification ${planification.name} created with data ${JSON.stringify(planification)}`);
                }
            } catch (error) {
                console.error("Error adding document: ", error);
            }
            this.dialogPlanification = false;
            this.loading = false;

            this.addPlanificationDialog = false;

            // update local planifications array    
           await  this.getPlanifications();

            this.planifications = this.processUserData(this.planifications, this.users);


        },

        async deleteWeek(planification, week) {

            let confirm = await this.$confirm(
                "Seguro que quieres eliminar esta semana?",
                {
                    color: "error", title: "Confirmacion",
                    buttonTrueText: "CONFIRMAR",
                    buttonFalseText: "CANCELAR"
                }
            );

            if (!confirm) return;

            const index = planification.weeks.indexOf(week);
            planification.weeks.splice(index, 1);
        },
        addWeek(planification) {
            let weeknumber = planification.weeks.length + 1;

            planification.weeks.push({
                name: 'Semana ' + weeknumber,
                days: [
                    { name: 'Dia 1', exercises: [] },
                ],
            });
        },
        editPlanification(planification) {

            this.selectPlanification(planification);


            this.planMembers = [];
            this.planNonMembers = [];
            this.trainingPlan = JSON.parse(JSON.stringify(planification));
            //this.addPlanificationDialog = true;
            this.$nextTick(() => {
                this.$vuetify.goTo(0);

                
                if (this.trainingPlan.type == 'text') {
                    this.$refs.text.set(this.trainingPlan.bloques);
                } else if (this.trainingPlan.type == 'listado') {
                    this.$refs.simple.set(this.trainingPlan.bloques);
                } else if (this.trainingPlan.type == 'calendar') {
                    this.$refs.calendar.set(this.trainingPlan.bloques);
                } else if (this.trainingPlan.type == 'matrix' || this.trainingPlan.type == 'matrixtext') {
                    //this.$refs.matrix.set(this.trainingPlan.bloques);
                }



            });

        },
        showEditPlanification() {
            this.addPlanificationDialog = true;
        },
        addExerciseBlock(day) {
            day.exercises.push({
                isblock: true,
                exercises: [
                    { name: 'Running' },
                    { name: 'Cycling' },
                    { name: 'Swimming' }
                ],
            })
        },
        async getMembersAndNonMembers(planification) {
            this.dialogPlanMembers = true;
            this.loadingDelete = true;
            let planId = planification.id;

            const users = this.users;


            // reset members and non members
            this.planMembers = [];
            this.planNonMembers = [];

            const members = [];
            const nonMembers = [];
            

            users.forEach(user => {
                const userData = user;
                
                if (userData.routines && userData.routines.includes(planId)) {
                    members.push(user);
                } 
            
                else {
                    nonMembers.push(user);
                }
            });

            this.planMembers = members;
            this.planNonMembers = nonMembers;
            this.loadingDelete = false;
        },


        async getMembersAndNonMembersMain(planification) {
            this.dialogMainWorkoutMembers = true;
            this.loadingDelete = true;
            let planId = planification.id;

            const users = this.users;

            // reset members and non members
            this.planMembers = [];
            this.planNonMembers = [];

            const members = [];
            const nonMembers = [];

            users.forEach(user => {
                const userData = user;
                if (userData.mainWorkout && userData.mainWorkout.plan_id == planId) {
                    members.push(user);
                } else {
                    nonMembers.push(user);
                }
            });

            this.planMembers = members;
            this.planNonMembers = nonMembers;
            this.loadingDelete = false;
        },

        async deletePlanMember(item) {
            let confirm = await this.$confirm(
                "Seguro que quieres eliminar este miembro?",
                {
                    color: "error", title: "Eliminar miembro",
                    buttonTrueText: "CONFIRMAR",
                    buttonFalseText: "CANCELAR"
                }
            );
            if (!confirm) return;
            this.loadingDelete = true;

            const db = getFirestore();

            const docRef = doc(db, `users/${item.civilId}`);

            let routines = item.routines;
            let index = routines.indexOf(this.trainingPlan.id);
            routines.splice(index, 1);

            await updateDoc(docRef, {
                routines: routines
            });

            this.$notify({
                group: 'feedback',
                title: 'Miembro eliminado',
                text: 'El miembro se ha eliminado correctamente',
                type: 'success',
                closeOnClick: false
            });

            this.planMembers.splice(this.planMembers.indexOf(item), 1);
            this.planNonMembers.push(item);

            // also update the count of workouts
            this.planifications = this.processUserData(this.planifications, this.users);

            this.loadingDelete = false;
        },
        async addPlanMember(item) {
            this.loadingDelete = true;
            const db = getFirestore();

            const docRef = doc(db, `users/${item.civilId}`);

            let routines = item.routines;
            routines.push(this.trainingPlan.id);

            await updateDoc(docRef, {
                routines: routines
            });

            this.$notify({
                group: 'feedback',
                title: 'Miembro agregado',
                text: 'El miembro se ha agregado correctamente',
                type: 'success',
            });

            this.planNonMembers.splice(this.planNonMembers.indexOf(item), 1);
            this.planMembers.push(item);

            // also update the count of workouts
            this.planifications = this.processUserData(this.planifications, this.users);


            this.loadingDelete = false;
        },
        clonePlanification(planification) {

            this.trainingPlan = JSON.parse(JSON.stringify({ ...planification }));
            delete this.trainingPlan.id;
            this.trainingPlan.name = this.trainingPlan.name + ' - Copia';
            //store new one 
            this.storePlanification(this.trainingPlan, true);

            //



        },
        async fetchExercises() {
            logAuditEvent('read', this.$store.state.Auth.token.claims.user_id, `Fetched exercises`);

            this.loading = true;
            const db = getFirestore();
            const querySnapshot = await getDocs(collection(db, "exercises"));
            this.exercises = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            this.loading = false;


        },
        removeFromEjercicioDiario(index, bloqueIndex) {
            this.trainingPlan.bloques[bloqueIndex].days[index].ejercicioDiario.splice(index, 1);
        },
        async showPlanification(planification) {
            planification.visible = true;
            // Update the planification in the database
            const db = getFirestore();
            const planificationRef = doc(db, `planifications`, planification.id);
            await updateDoc(planificationRef, { visible: true });

            logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `Planification ${planification.name} shown`);

            this.$notify({
                group: 'feedback',
                title: 'Planificación mostrada',
                text: 'La planificación se ha mostrado correctamente',
                type: 'success',
            });
        },
        async hidePlanification(planification) {
            planification.visible = false;
            // Update the planification in the database
            const db = getFirestore();
            const planificationRef = doc(db, `planifications`, planification.id);
            await updateDoc(planificationRef, { visible: false });
            
            logAuditEvent('update', this.$store.state.Auth.token.claims.user_id, `Planification ${planification.name} hidden`);
            
            this.$notify({
                group: 'feedback',
                title: 'Planificación oculta',
                text: 'La planificación se ha ocultado correctamente',
                type: 'success',
            });
        }

    },
    computed: {
        allBlocksList() {
            // Return a list of objects of all blocks in all the (matrix/matrixtext) planifications, each block should have(planificationId,planificationName, blockId, title)
            let blocks = [];
            this.planifications.forEach(planification => {
                if (planification.type == 'matrix' || planification.type == 'matrixtext') {
                    planification.bloques.forEach(bloque => {
                        blocks.push({
                            planificationId: planification.id,
                            planificationName: planification.name,
                            blockId: planification.bloques.indexOf(bloque),
                            title: bloque.title || ''
                        });
                    });
                }
            });
            return blocks;
        }
    }
};
</script>

<style>
.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent !important;
}

.isCapacitorDialog {
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;

}

.HOLDTABLES .text-start {
    padding-left: 4px !important;
    padding-right: 4px !important;
}


.small-caption {
    font-size: 10px;
}
</style>
