<template>
    <v-row no-gutters>
        <v-col cols="12" md="3" class="hidden-sm-and-down">
            <!-- Drawer for adding new exercises -->
            <v-navigation-drawer width="100%">
                <v-toolbar flat class="pl-2">
                    <v-icon left>mdi-cursor-move</v-icon>
                    Arrastra para agregar
                </v-toolbar>
                <v-card flat class="fill-height rounded-0">
                    <v-card-text class="pa-0 ">

                        <v-list class="py-0 fill-height" dense>
                            <draggable v-model="exercises" :group="{ name: 'exercises', pull: 'clone', put: false }"
                                :clone="cloneExercise">
                                <v-list-item v-for="(exercise, index) in exercises" :key="index" @click=""
                                    style="cursor: move">
                                    <v-list-item-avatar>
                                        <v-icon small>mdi-dumbbell</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ exercise.name }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </draggable>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-navigation-drawer>
        </v-col>

        <v-col cols="12" md="9">
            <v-toolbar flat class="pl-2">
                <v-icon left>mdi-format-list-bulleted</v-icon>
                Listado
                <v-spacer></v-spacer>

                <!-- Add Cluster Button -->
                <v-btn @click="createClusterFromSelected" elevation="0" text :disabled="selectedExercises.length == 0">
                    <v-icon>mdi-plus-box</v-icon> Crear Grupo
                </v-btn>

                <!-- Clean list -->


                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on"  @click="cleanExercises" elevation="0" text :disabled="dayExercises.length == 0">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                    <span>Limpiar lista</span>

                </v-tooltip>

            </v-toolbar>

            <v-card flat>
                <v-card-text class=" pa-2">
                    <v-list dense class="py-0 ">

                        <!-- Draggable when the list is empty with a centered text and drop like styles -->
                        <draggable v-if="dayExercises.length == 0" v-model="dayExercises" group="exercises"
                            class="empty-list">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-center">
                                        Arrastra ejercicios aquí
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </draggable>



                        <!-- Draggable list containing both exercises and clusters -->
                        <draggable v-else v-model="dayExercises" group="exercises" handle=".drag-handle"
                            class="empty-list2">


                            <v-list-item dense v-for="(exercise, index) in dayExercises" :key="index"
                                @click="toggleSelect(index)" :class="{ 'selected-item': isSelected(index) }"
                                class="drag-handle px-0">

                                <!-- Render clusters differently -->
                                <div v-if="exercise.isCluster && exercise.isCluster == true" class="cluster-container">
                                    <v-list-item class="drag-handle">
                                        <v-list-item-avatar>
                                            {{ index + 1 }} )
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title style="font-weight: bold;text-transform:uppercase">

                                                <template v-if="exercise.title">
                                                    {{ exercise.title }}
                                                </template>
                                                <template v-else>
                                                    {{ exercise.exercises.length }} Ejercicios en este grupo
                                                </template>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ exercise.sets }} sets
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-toolbar-items>
                                            <v-btn icon small @click="removeExercise(index)">
                                                <v-icon color="error" small>mdi-close</v-icon>
                                            </v-btn>

                                            <v-menu offset-y :close-on-content-click="false" width="300">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" text small icon>
                                                        <v-icon x-small size="15">mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <!-- Menu content for editing -->
                                                <v-card>
                                                    <v-card-text>
                                                        <v-row>

                                                            <v-col cols="12">
                                                                <v-text-field label="Título" v-model="exercise.title"
                                                                    dense hide-details="" outlined></v-text-field>
                                                            </v-col>


                                                            <v-col cols="12">
                                                                <v-text-field label="Sets" v-model="exercise.sets"
                                                                    type="number" min="1" step="1" dense hide-details=""
                                                                    outlined></v-text-field>
                                                            </v-col>

                                                             <v-col cols="12">
                                                                <v-text-field label="Descanso (segundos)" v-model="exercise.restBetweenSets"
                                                                    type="number" min="1" step="1" dense hide-details=""
                                                                    outlined></v-text-field>
                                                            </v-col>

<!-- 
                                                            <v-col cols="6">
                                                                <v-text-field label="Ejercicios"
                                                                    v-model="exercise.exercises.length" type="number"
                                                                    min="1" step="1" dense hide-details=""
                                                                    outlined></v-text-field>

                                                            </v-col> -->
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-menu>
                                        </v-toolbar-items>
                                    </v-list-item>

                                    <!-- Display exercises inside the cluster -->
                                    <v-list dense class="cluster-exercise-list">


                                        <draggable v-model="exercise.exercises" group="exercises"
                                            handle=".drag-handle-cluster">
                                            <v-list-item v-for="(subExercise, exIndex) in exercise.exercises"
                                                :key="exIndex" class="clustered-exercise">
                                                <v-list-item-avatar>


                                                    {{ index + 1 }}.{{ exIndex + 1 }}
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ exerciseHashIdNames[subExercise.id]
                                                        }}</v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ subExercise.type === 'reps' ?
                                                            subExercise.value + ' reps' : subExercise.value + `''` }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-toolbar-items>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn v-on="on" icon small @click="removeExercise(index)">
                                                                <v-icon color="error" small>mdi-close</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Eliminar</span>
                                                    </v-tooltip>

                                                    <v-menu offset-y :close-on-content-click="false" width="300">
                                                        <template v-slot:activator="{ on }">


                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on: tooltipOn }">
                                                                    <v-btn v-on="Object.assign({}, on, tooltipOn)" text
                                                                        small icon>
                                                                        <v-icon x-small size="15">mdi-pencil</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Editar</span>
                                                            </v-tooltip>



                                                        </template>


                                                        <!-- Menu content for editing -->
                                                        <v-card>
                                                            <v-card-text>
                                                                <v-select v-model="subExercise.type"
                                                                    :items="exerciseTypes" label="Tipo" dense
                                                                    outlined></v-select>
                                                                <v-row>
                                                                    <v-col cols="6">
                                                                        <v-text-field label="Sets"
                                                                            v-model="exercise.sets" type="number"
                                                                            min="1" step="1" dense hide-details=""
                                                                            outlined></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="6">
                                                                        <v-text-field v-model="subExercise.value"
                                                                            :label="subExercise.type === 'reps' ? 'Repeticiones' : 'Segundos'"
                                                                            type="number" min="1" step="1" dense
                                                                            hide-details="" outlined></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-menu>

                                                    <!--drag handle clustr-->
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn v-on="on" text small icon
                                                                class="drag-handle-cluster">
                                                                <v-icon x-small size="15">mdi-cursor-move</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Reordenar</span>
                                                    </v-tooltip>
                                                </v-toolbar-items>


                                            </v-list-item>
                                        </draggable>
                                    </v-list>
                                </div>

                                <!-- Render regular exercises -->
                                <template v-else>
                                    <v-list-item-avatar>
                                        {{ index + 1 }} )
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title style="font-weight: bold;text-transform:uppercase">
                                            {{ exerciseHashIdNames[exercise.id] }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ exercise.sets }} x {{ exercise.type === 'reps' ? exercise.value +
                                                'reps' :
                                                exercise.value + `''` }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-toolbar-items>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon small @click="removeExercise(index)" v-on="on">
                                                    <v-icon color="error" small>mdi-close</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>






                                        <v-menu offset-y :close-on-content-click="false" width="300">
                                            <template v-slot:activator="{ on }">


                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltipOn }">
                                                        <v-btn v-on="Object.assign({}, on, tooltipOn)" text small icon>
                                                            <v-icon x-small size="15">mdi-pencil</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Editar</span>
                                                </v-tooltip>



                                            </template>
                                            <!-- Menu content for editing -->
                                            <v-card>
                                                <v-card-text>
                                                    <v-select v-model="exercise.type" :items="exerciseTypes"
                                                        label="Tipo" dense outlined></v-select>
                                                    <v-row>
                                                        <v-col cols="6">
                                                            <v-text-field label="Sets" v-model="exercise.sets"
                                                                type="number" min="1" step="1" dense hide-details=""
                                                                outlined></v-text-field>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field v-model="exercise.value"
                                                                :label="exercise.type === 'reps' ? 'Repeticiones' : 'Segundos'"
                                                                type="number" min="1" step="1" dense hide-details=""
                                                                outlined></v-text-field>
                                                        </v-col>

                                                         <v-col cols="12">
                                                                <v-text-field label="Descanso (segundos)" v-model="exercise.restBetweenSets"
                                                                    type="number" min="1" step="1" dense hide-details=""
                                                                    outlined></v-text-field>
                                                            </v-col>
                                                    </v-row>



                                                </v-card-text>
                                            </v-card>
                                        </v-menu>
                                    </v-toolbar-items>
                                </template>
                            </v-list-item>





                        </draggable>

                        <v-menu offset-y :close-on-content-click="false" width="300">
                            <template v-slot:activator="{ on }">
                                <v-list-item v-on="on">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon left>
                                                mdi-plus
                                            </v-icon>
                                            Agregar Ejercicio
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <!-- Menu content for adding exercise -->
                            <v-card>
                                <v-card-text>
                                    <v-select v-model="newExercise.type" :items="exerciseTypes" label="Tipo" dense
                                        outlined></v-select>

                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field label="Sets" v-model="newExercise.sets" type="number" min="1"
                                                step="1" dense outlined></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="newExercise.value"
                                                :label="newExercise.type === 'reps' ? 'Repeticiones' : 'Segundos'"
                                                type="number" min="1" step="1" dense outlined></v-text-field>
                                        </v-col>
                                    </v-row>



                                    <v-autocomplete v-model="newExercise.id" :items="exercises" item-text="name"
                                        hide-details="" item-value="id" label="Seleccionar ejercicio" dense
                                        outlined></v-autocomplete>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="addExercise">
                                        <v-icon left>
                                            mdi-plus
                                        </v-icon>
                                        Agregar</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu>


                    </v-list>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import draggable from 'vuedraggable';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

export default {
    props: {
        exercises: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    components: {
        draggable,
    },
    data() {
        return {
            drawer: false,
            dayExercises: [], 
            selectedExercises: [], 
            exerciseTypes: [
                { text: 'Repeticiones', value: 'reps' },
                { text: 'Tiempo', value: 'time' },
            ],
            exerciseHashIdNames: {},
            newExercise: {
                id: null,
                sets: 1,
                type: 'reps',
                value: 10,
            },
        };
    },
    mounted() {
    },
    methods: {
        
        cloneExercise({ id }) {
            return {
                id,
                sets: 1,
                type: 'reps',
                value: 10,
            };
        },
        cleanExercises() {
            this.dayExercises = [];
            this.selectedExercises = [];
        },
        toggleSelect(index) {
            const selectedIndex = this.selectedExercises.indexOf(index);

            //check if its a cluster and return false
            if (this.dayExercises[index].isCluster) {
                return false;
            }

            if (selectedIndex === -1) {
                this.selectedExercises.push(index);
            } else {
                this.selectedExercises.splice(selectedIndex, 1);
            }
        },
        isSelected(index) {
            return this.selectedExercises.includes(index);
        },
        createClusterFromSelected() {
            if (this.selectedExercises.length > 0) {
                const selectedExercisesData = this.selectedExercises.map(index => this.dayExercises[index]);
                const newCluster = {
                    id: `cluster-${Date.now()}`, // Unique ID for cluster
                    type: 'reps',
                    isCluster: true,
                    title: null,
                    sets: 1,
                    exercises: selectedExercisesData,
                };

                // Add new cluster to dayExercises
                this.dayExercises.push(newCluster);

                // Remove selected exercises from dayExercises
                this.dayExercises = this.dayExercises.filter((_, index) => !this.selectedExercises.includes(index));
                this.selectedExercises = []; // Clear the selection
            }
        },
        removeExercise(index) {
            this.dayExercises.splice(index, 1);
        },
        addExercise() {
            const selectedExercise = this.exercises.find(ex => ex.id === this.newExercise.id);
            if (selectedExercise) {
                const newExerciseData = {
                    id: selectedExercise.id,
                    sets: this.newExercise.sets,
                    type: this.newExercise.type,
                    value: this.newExercise.value,
                };
                this.dayExercises.push(newExerciseData);

                // Reset the form
                this.newExercise = {
                    id: null,
                    sets: 1,
                    type: 'reps',
                    value: 10,
                };
            }
        },
        get(){
            return this.dayExercises;
        },
        set(data){
            this.dayExercises = data;
        }
    },
    watch: {
        exercises: {
            handler() {
                this.exerciseHashIdNames = this.exercises.reduce((acc, ex) => {
                    acc[ex.id] = ex.name;
                    return acc;
                }, {});
            },
            immediate: true,
        },
    },
};
</script>
<style scoped>
.selected-item {
    background-color: #e0f7fa;
    /* Highlight selected items */
}

.cluster-container {
    width: 100%;
    background-color: #f0f4f7;
    border-left: 5px solid #42a5f5;

}



.clustered-exercise {
    margin-bottom: 5px;
}



.drag-handle {
    cursor: move;
}

.theme--dark .selected-item {
    background-color: rgba(255, 255, 255, 0.2);
}

.theme--light .selected-item {
    background-color: rgba(0, 0, 0, 0.2);
}


.theme--dark .cluster-container {
    background-color: #1f2d3d;
    border-left-color: #f0f4f7;
}

.theme--light .cluster-container {
    background-color: #f0f4f7;
    border-left-color: #1f2d3d;
}

.cluster-container {
    width: 100%;
    border-left: 5px solid;
}


.empty-list {
    height: 200px;
    justify-content: center;
    align-items: center;
    border: 2px dashed #e0e0e0;
    padding: 5px;
    border-radius: 10px;
}

.empty-list2 {
    padding: 5px;
    border-radius: 10px;
    overflow: hidden;
    min-height: 200px;
    border: 2px dashed #e0e0e0;
}



.drag-handle {
    cursor: move;
}
</style>
